const contact = {
    title: 'Obtener ayuda',
    description: 'Para solicitudes de ayuda o comentarios sobre el producto, contáctenos a través de los siguientes métodos.',
    discord: {
        title: 'Únete a la comunidad de Discord',
        description: 'Únete a nuestro canal público para chatear con otros desarrolladores',
        button: 'Únete',
    },
    github: {
        title: 'Únete a las Discusiones de GitHub',
        description: 'Haz preguntas y comparte ideas en GitHub',
        button: 'Abrir',
    },
    email: {
        title: 'Contacta con soporte por correo electrónico',
        description: 'Envíanos un correo electrónico para obtener más información y ayuda.',
        button: 'Enviar',
    },
};
export default contact;
