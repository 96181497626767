const contact = {
    title: '獲得幫助',
    description: '欲查詢幫助或提供產品反饋，請透過以下方式聯絡我們。',
    discord: {
        title: '加入 Discord 社群',
        description: '加入我們的公開頻道與其他開發者聊天',
        button: '加入',
    },
    github: {
        title: '加入 GitHub 討論',
        description: '在 GitHub 上提問並分享意見',
        button: '打開',
    },
    email: {
        title: '透過電子郵件聯絡客服支援',
        description: '發送電子郵件以獲取進一步信息和幫助',
        button: '發送',
    },
};
export default contact;
