const contact = {
    title: "Obtenir de l'aide",
    description: "Pour les demandes d'aide ou les commentaires sur le produit, veuillez nous contacter par l'un des moyens suivants.",
    discord: {
        title: 'Rejoindre la communauté Discord',
        description: "Rejoignez notre canal public pour discuter avec d'autres développeurs",
        button: 'Rejoindre',
    },
    github: {
        title: 'Rejoignez les Discussions GitHub',
        description: 'Posez des questions et partagez des idées sur GitHub',
        button: 'Ouvrir',
    },
    email: {
        title: 'Contacter le support par e-mail',
        description: "Envoyez-nous un e-mail pour obtenir des informations complémentaires et de l'aide",
        button: 'Envoyer',
    },
};
export default contact;
