const contact = {
    title: 'ヘルプを得る',
    description: 'ヘルプ要求または製品フィードバックについては、次の方法を使用してお問い合わせください。',
    discord: {
        title: 'Discord コミュニティに参加する',
        description: '他の開発者とチャットするためにパブリックチャンネルに参加してください',
        button: '参加する',
    },
    github: {
        title: 'GitHub ディスカッションに参加する',
        description: 'GitHub で質問をしたり、アイデアを共有したりしてください',
        button: '開く',
    },
    email: {
        title: 'メールでサポートに連絡する',
        description: '詳細情報やヘルプをご希望の場合は、私たちにメッセージを送ってください',
        button: '送信する',
    },
};
export default contact;
