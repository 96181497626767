const contact = {
    title: 'Получить помощь',
    description: 'Для запросов на помощь и отзывов о продукте, свяжитесь с нами через следующие методы.',
    discord: {
        title: 'Присоединяйтесь к Discord-сообществу',
        description: 'Присоединяйтесь к нашему открытому каналу, чтобы общаться с другими разработчиками',
        button: 'Присоединиться',
    },
    github: {
        title: 'Присоединитесь к обсуждениям на GitHub',
        description: 'Задавайте вопросы и делитесь идеями на GitHub',
        button: 'Открыть',
    },
    email: {
        title: 'Связаться с поддержкой по электронной почте',
        description: 'Отправьте нам электронное письмо для получения дополнительной информации и помощи',
        button: 'Отправить',
    },
};
export default contact;
