const contact = {
    title: 'Get help',
    description: 'For help requests or product feedback, please contact us through the following methods.',
    discord: {
        title: 'Join Discord Community',
        description: 'Join our public channel to chat with other developers',
        button: 'Join',
    },
    github: {
        title: 'Join GitHub Discussions',
        description: 'Ask questions, and share ideas on GitHub',
        button: 'Open',
    },
    email: {
        title: 'Contact support via email',
        description: 'Send us an email for further information and help',
        button: 'Send',
    },
};
export default contact;
