const tenants = {
    title: 'Configuraciones',
    description: 'Administre eficientemente la configuración del inquilino y personalice su dominio.',
    tabs: {
        settings: 'Configuraciones',
        domains: 'Dominios',
    },
    settings: {
        title: 'CONFIGURACIONES',
        tenant_id: 'ID del inquilino',
        tenant_name: 'Nombre del inquilino',
        environment_tag: 'Etiqueta del entorno',
        environment_tag_description: 'Las etiquetas no alteran el servicio. Simplemente te guían para diferenciar diversos entornos.',
        environment_tag_development: 'Des',
        environment_tag_staging: 'Pruebas',
        environment_tag_production: 'Prod',
        tenant_info_saved: 'Información del inquilino guardada correctamente.',
    },
    deletion_card: {
        title: 'ELIMINAR',
        tenant_deletion: 'Eliminar inquilino',
        tenant_deletion_description: 'Eliminar el inquilino resultará en la eliminación permanente de todos los datos de usuario y configuraciones asociadas. Por favor, proceda con precaución.',
        tenant_deletion_button: 'Eliminar inquilino',
    },
    create_modal: {
        title: 'Crear inquilino',
        subtitle: 'Cree un nuevo inquilino para separar recursos y usuarios.',
        create_button: 'Crear inquilino',
        tenant_name_placeholder: 'Mi inquilino',
    },
    delete_modal: {
        title: 'Eliminar inquilino',
        description_line1: '¿Está seguro de que desea eliminar su inquilino "<span>{{name}}</span>" con etiqueta de sufijo de entorno"<span>{{tag}}</span>"? Esta acción no se puede deshacer y resultará en la eliminación permanente de todos sus datos e información de cuenta.',
        description_line2: 'Antes de eliminar la cuenta, quizás podamos ayudarlo. <span><a>Contáctenos por correo electrónico</a></span>',
        description_line3: 'Si desea continuar, ingrese el nombre del inquilino "<span>{{name}}</span>" para confirmar.',
        delete_button: 'Eliminar permanentemente',
    },
    tenant_landing_page: {
        title: 'Todavía no has creado un tenant',
        description: 'Para empezar a configurar tu proyecto con Logto, por favor crea un nuevo tenant. Si necesitas cerrar la sesión o eliminar tu cuenta, simplemente haz clic en el botón de avatar en la esquina superior derecha.',
        create_tenant_button: 'Crear tenant',
    },
};
export default tenants;
