const contact = {
    title: '取得協助',
    description: '若需協助申請或產品反饋，請透過下列方法與我們聯繫。',
    discord: {
        title: '加入 Discord 社群',
        description: '進入我們的公開頻道與其他開發人員聊天',
        button: '加入',
    },
    github: {
        title: '加入 GitHub 討論',
        description: '在 GitHub 上提問並分享意見',
        button: '開啟',
    },
    email: {
        title: '透過電子郵件聯繫支援',
        description: '發送電子郵件以獲得更多資訊和協助',
        button: '發送',
    },
};
export default contact;
