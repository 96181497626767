const contact = {
    title: 'Obter ajuda',
    description: 'Para solicitações de ajuda ou feedback do produto, entre em contato conosco pelos seguintes métodos.',
    discord: {
        title: 'Participe da comunidade do Discord',
        description: 'Junte-se ao nosso canal público para conversar com outros desenvolvedores',
        button: 'Participar',
    },
    github: {
        title: 'Junte-se às Discussões do GitHub',
        description: 'Faça perguntas e compartilhe ideias no GitHub',
        button: 'Abrir',
    },
    email: {
        title: 'Entre em contato com o suporte via email',
        description: 'Envie um email para obter mais informações e ajuda',
        button: 'Enviar',
    },
};
export default contact;
