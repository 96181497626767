const contact = {
    title: 'Obter ajuda',
    description: 'Para solicitações de ajuda ou feedback do produto, entre em contato conosco através dos seguintes métodos.',
    discord: {
        title: 'Participar da comunidade Discord',
        description: 'Junte-se ao nosso canal público para conversar com outros desenvolvedores',
        button: 'Entrar',
    },
    github: {
        title: 'Junte-se às Discussões do GitHub',
        description: 'Faça perguntas e partilhe ideias no GitHub',
        button: 'Abrir',
    },
    email: {
        title: 'Contato com suporte via e-mail',
        description: 'Envie-nos um e-mail para obter mais informações e ajuda',
        button: 'Enviar',
    },
};
export default contact;
