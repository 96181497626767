const tenants = {
    title: 'Definições',
    description: 'Gerir eficientemente as configurações do inquilino e personalizar o seu domínio.',
    tabs: {
        settings: 'Definições',
        domains: 'Domínios',
    },
    settings: {
        title: 'DEFINIÇÕES',
        tenant_id: 'ID do Inquilino',
        tenant_name: 'Nome do Inquilino',
        environment_tag: 'Tag de Ambiente',
        environment_tag_description: 'As etiquetas não alteram o serviço. Simplesmente guiam-no para diferenciar vários ambientes.',
        environment_tag_development: 'Dev',
        environment_tag_staging: 'Staging',
        environment_tag_production: 'Prod',
        tenant_info_saved: 'A informação do arrendatário foi guardada com sucesso.',
    },
    deletion_card: {
        title: 'ELIMINAR',
        tenant_deletion: 'Eliminar inquilino',
        tenant_deletion_description: 'A eliminação do inquilino resultará na remoção permanente de todos os dados de utilizador e configuração associados. Por favor, proceda com cuidado.',
        tenant_deletion_button: 'Eliminar inquilino',
    },
    create_modal: {
        title: 'Criar inquilino',
        subtitle: 'Crie um novo inquilino para separar recursos e utilizadores.',
        create_button: 'Criar inquilino',
        tenant_name_placeholder: 'Meu inquilino',
    },
    delete_modal: {
        title: 'Eliminar inquilino',
        description_line1: 'Tem a certeza de que pretende eliminar o seu inquilino "<span>{{name}}</span>" com a etiqueta de sufixo de ambiente "<span>{{tag}}</span>"? Esta ação não pode ser desfeita e resultará na eliminação permanente de todos os seus dados e informações da conta.',
        description_line2: 'Antes de eliminar a conta, podemos ajudá-lo. <span><a>Contacte-nos por email</a></span>',
        description_line3: 'Se desejar continuar, introduza o nome do inquilino "<span>{{name}}</span>" para confirmar.',
        delete_button: 'Eliminar permanentemente',
    },
    tenant_landing_page: {
        title: 'Ainda não criou um inquilino',
        description: 'Para começar a configurar o seu projeto com o Logto, crie um novo inquilino. Se precisar de fazer logout ou excluir a sua conta, basta clicar no botão avatar no canto superior direito.',
        create_tenant_button: 'Criar inquilino',
    },
};
export default tenants;
