const tenants = {
    title: '설정',
    description: '테넌트 설정을 효율적으로 관리하고 도메인을 사용자 정의합니다.',
    tabs: {
        settings: '설정',
        domains: '도메인',
    },
    settings: {
        title: '설정',
        tenant_id: '테넌트 ID',
        tenant_name: '테넌트 이름',
        environment_tag: '환경 태그',
        environment_tag_description: '태그는 서비스를 변경하지 않습니다. 단지 다양한 환경을 구별하는 데 도움을 줍니다.',
        environment_tag_development: '개발',
        environment_tag_staging: '스테이징',
        environment_tag_production: '프로드',
        tenant_info_saved: '세입자 정보가 성공적으로 저장되었습니다.',
    },
    deletion_card: {
        title: '삭제',
        tenant_deletion: '테넌트 삭제',
        tenant_deletion_description: '테넌트를 삭제하면 관련된 모든 사용자 데이터와 설정이 영구적으로 삭제됩니다. 신중하게 진행해주십시오.',
        tenant_deletion_button: '테넌트 삭제',
    },
    create_modal: {
        title: '테넌트 만들기',
        subtitle: '자원 및 사용자를 분리하기 위한 새 테넌트를 만드세요.',
        create_button: '테넌트 만들기',
        tenant_name_placeholder: '내 테넌트',
    },
    delete_modal: {
        title: '테넌트 삭제',
        description_line1: '환경 접미사 "<span>{{tag}}</span>"이(가) 붙은 "<span>{{name}}</span>" 테넌트를 삭제하시겠습니까? 이 작업은 실행 취소할 수 없으며, 모든 데이터 및 계정 정보가 영구적으로 삭제됩니다.',
        description_line2: '계정을 삭제하기 전에 도움이 필요할 수 있습니다. <span><a>이메일로 연락</a></span>해주시면 도움을 드리겠습니다.',
        description_line3: '삭제하려는 테넌트 이름 "<span>{{name}}</span>"을(를) 입력하여 확인하십시오.',
        delete_button: '영구 삭제',
    },
    tenant_landing_page: {
        title: '아직 테넌트를 만들지 않았습니다.',
        description: 'Logto 를 사용하여 프로젝트를 구성하려면 새 테넌트를 만드세요. 로그아웃하거나 계정을 삭제하려면 오른쪽 상단 모서리에있는 아바타 버튼을 클릭하세요.',
        create_tenant_button: '테넌트 만들기',
    },
};
export default tenants;
