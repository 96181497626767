const contact = {
    title: '지원 받기',
    description: '도움 요청 또는 제품 피드백을 위해서, 아래의 방법을 통해 저희에게 문의해주세요.',
    discord: {
        title: '디스코드 커뮤니티 가입하기',
        description: '다른 개발자들과 함께 채팅할 수 있는 공개 채널에서 저희와 함께해주세요.',
        button: '가입하기',
    },
    github: {
        title: 'GitHub 토론에 참여하세요',
        description: 'GitHub 에서 질문을 하고 아이디어를 공유하세요',
        button: '열기',
    },
    email: {
        title: '이메일로 지원팀과 연락하기',
        description: '더 많은 정보나 도움을 위해 이메일을 보내주세요.',
        button: '보내기',
    },
};
export default contact;
